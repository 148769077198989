var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 bg-white rounded-lg"},[_c('ReportCharts',{attrs:{"urls":_vm.urls,"mandatory-filter":[
      {
        name: 'report_date_month_range',
        title: 'Date range',
        valueFieldName: '.select',
      },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }